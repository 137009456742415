import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying vehicle information in the email report
const ERVehicleInfoSlide = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer vehicle_info_with_green_tick">
            <div className="top_part">
                {/* Display the car info icon */}
                <img
                    alt=""
                    src="/images/email_report/car_info.svg"
                />
                <p className="m-0">Vehicle Information</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="data_part_container">

                    {/* VIN Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the VIN number is verified */}
                            {reportData?.saintAPIDetails?.vinNumberVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">VIN No. :</p> {/* Label for VIN number */}
                        </div>
                        <div className="right_part">
                            {/* Display the VIN number or 'NA' if not available */}
                            <p className="m-0">{reportData?.vinNo || 'NA'}</p>
                        </div>
                    </div>

                    {/* Licence Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Licence No. :</p> {/* Label for Licence number */}
                        </div>
                        <div className="right_part">
                            {/* Display the licence number or 'NA' if not available */}
                            <p className="m-0">{reportData?.licenceNumber || 'NA'}</p>
                        </div>
                    </div>

                    {/* Registration Year section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Reg  Year. :</p> {/* Label for Licence number */}
                        </div>
                        <div className="right_part">
                            {/* Display registrationYear or yearOfVehicle or 'NA' if not available */}
                            <p className="m-0">{reportData?.registrationYear || reportData?.yearOfVehicle || 'NA'}</p>
                        </div>
                    </div>

                    {/* Registration Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the registration number is verified */}
                            {reportData?.saintAPIDetails?.regNumberVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">Registration No. :</p> {/* Label for Registration number */}
                        </div>
                        <div className="right_part">
                            {/* Display the registration number or 'NA' if not available */}
                            <p className="m-0">{reportData?.registrationNumber || 'NA'}</p>
                        </div>
                    </div>

                    {/* Engine Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the engine number is verified */}
                            {reportData?.saintAPIDetails?.engineNumberVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">Engine No. :</p> {/* Label for Engine number */}
                        </div>
                        <div className="right_part">
                            {/* Display the engine number or 'NA' if not available */}
                            <p className="m-0">{reportData?.engineNumber || 'NA'}</p>
                        </div>
                    </div>

                    {/* Color section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the color is verified */}
                            {reportData?.saintAPIDetails?.colorVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">Color :</p> {/* Label for Color */}
                        </div>
                        <div className="right_part">
                            {/* Display the color or 'NA' if not available */}
                            <p className="m-0">{reportData?.color || 'NA'}</p>
                        </div>
                    </div>

                    {/* Make section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the make is verified */}
                            {reportData?.saintAPIDetails?.makeDescVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">Make :</p> {/* Label for Make */}
                        </div>
                        <div className="right_part">
                            {/* Display the make or 'NA' if not available */}
                            <p className="m-0">{reportData?.make || 'NA'}</p>
                        </div>
                    </div>

                    {/* Model section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            {/* Display green tick if the model is verified */}
                            {reportData?.saintAPIDetails?.modelVerified &&
                                <img
                                    src="/images/email_report/green_tick.svg"
                                    alt=""
                                />
                            }
                            <p className="m-0">Model :</p> {/* Label for Model */}
                        </div>
                        <div className="right_part">
                            {/* Display the model or 'NA' if not available */}
                            <p className="m-0">{reportData?.model || 'NA'}</p>
                        </div>
                    </div>
                </div>

                {/* Section to display the green tick explanation */}
                {reportData?.saintAPIDetails &&
                    <div className="green_tick_representation">
                        <div className="left_part">
                            <img
                                src="/images/email_report/grey_info_icon.svg"
                                alt=""
                            />
                        </div>
                        <div className="right_part">
                            <img
                                src="/images/email_report/green_tick.svg"
                                alt=""
                            />
                            <p className="m-0">
                                This represents the above data has been validated.
                            </p> {/* Explanation for the green tick */}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ERVehicleInfoSlide;
